import dynamic from 'next/dynamic';

const modals = {
    DevModal: dynamic(() => import('./DevModal')),
    ListMenu: dynamic(() => import('./ListMenu')),
    SortingModal: dynamic(() => import('./SortingModal')),
    CreateListModal: dynamic(() => import('./CreateListModal')),
    CreateNewList: dynamic(() => import('./CreateNewList')),
    IncludedArticles: dynamic(() => import('./IncludedArticles')),
    ItemMenu: dynamic(() => import('./ItemMenu')),
    MoveToAnotherList: dynamic(() => import('./MoveToAnotherList')),
    RecommendationsModal: dynamic(() => import('./RecommendationsModal')),
    RemoveList: dynamic(() => import('./RemoveList')),
    RenameList: dynamic(() => import('./RenameList')),
    SessionInvalid: dynamic(() => import('./SessionInvalid')),
    ShareList: dynamic(() => import('./ShareList')),
    SignUpNotifications: dynamic(() => import('./SignUpNotifications')),
    MeasurementsModal: dynamic(() => import('./MeasurementsModal')),
    MultiSelectionModal: dynamic(() => import('./MultiSelectionModal')),
    StoreIntentPrompt: dynamic(
        () => import('./StoreIntentPrompt/StoreIntentPrompt')
    ),
    IncludedDesignItems: dynamic(() => import('./IncludedDesignItems')),
    ContentModal: dynamic(() => import('./ContentModal')),
    AddToCartFailedModal: dynamic(() => import('./AddToCartFailedModal')),
    MapModal: dynamic(() => import('./MapModal')),
} as const;

export default modals;
